<script setup>
import {ChevronDownIcon} from '@heroicons/vue/24/outline'
import {ref} from "vue";
import {Menu,Dropdown} from 'ant-design-vue';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  showArrow: {
    type: Boolean,
    default: true,
  },
  placement: {//bottomLeft | bottom | bottomRight | topLeft | top | topRight
    type: String,
    default: 'bottom',
  }
});

const wrapper = ref();

const getPopupContainer = () => {
  return wrapper.value;
}

</script>

<template>
  <div ref="wrapper" class="wrapper">
    <Dropdown
        :trigger="['click']"
        :getPopupContainer="getPopupContainer"
        :placement="placement"
    >
      <div class="flex items-center cursor-pointer hover:opacity-70">
        <div class="font-semibold text-gray-900">
          <slot name="label"/>
        </div>
        <ChevronDownIcon
            v-if="showArrow"
            class="h-5 w-5 text-gray-400 ml-2.5"
        />
      </div>
      <template #overlay>
        <Menu>
          <slot/>
        </Menu>
      </template>
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
.wrapper :deep(.ant-dropdown-menuUtil) {
  padding: 8px;
}
</style>
